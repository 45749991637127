import { SHOW_SUCCESS, SHOW_INFO, SHOW_ERROR, REMOVE_NOTIFICATION } from './notification.contants';

const notificationReducer = (state = null, action) => {
	switch (action.type) {
		case SHOW_SUCCESS:
		case SHOW_INFO:
		case SHOW_ERROR:
		case REMOVE_NOTIFICATION:
			return action.data;

		default:
			return state;
	}
};

export default notificationReducer;
