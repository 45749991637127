import React from 'react';
import { Link } from 'react-router-dom';
const Post = (props = { posts: [], updateStatus: () => {} }) => {
	if (props.posts.length === 0) {
		return (
			<tr>
				<td colSpan="9" rowSpan="2">
					<div style={{ textAlign: 'center' }}>
						{props.loading ? (
							<h4>
								Loading...{' '}
								<span role="img" aria-label="loading">
									⏳
								</span>
							</h4>
						) : (
							<>
								<h4>
									No posts found. Please create One{' '}
									<span role="img" aria-label="smiley">
										😊
									</span>
								</h4>
								<Link to="/admin/create-post">
									<button className="btn btn-info">Create New Post</button>
								</Link>
							</>
						)}
					</div>
				</td>
			</tr>
		);
	}
	return props.posts.map((post, i) => (
		<tr key={post._id}>
			<td>{i + 1}</td>
			<td>{post.title}</td>
			<td>{post.subTitle}</td>
			<td>{post.category}</td>
			<td>{post.tags}</td>
			<td className="edit">
				<Link to={`/admin/edit-post/${post._id}`}>
					<button data-id={post._id} className={`btn btn-success`}>
						Edit
					</button>
				</Link>
			</td>
			<td className="status">
				<button
					data-id={post._id}
					data-value={post.status}
					onClick={props.updateStatus}
					className={`btn ${post.status === 1 ? 'btn-primary' : 'btn-warning'}`}
				>
					{post.status === 1 ? 'Published' : 'Publish'}
				</button>
			</td>
			<td className="delete">
				<button
					data-id={post._id}
					onClick={(e) => {
						props.deletePost(e.target.dataset.id);
					}}
					className={`btn btn-danger`}
				>
					Delete
				</button>
			</td>
		</tr>
	));
};

export default Post;
