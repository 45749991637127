import { USER_LOGIN, USER_LOGOUT } from './login.constants';

let user = JSON.parse(localStorage.getItem('user'));
const defaultState = user
  ? { isloggedIn: true, ...user }
  : {
      isloggedIn: false,
      token: '',
    };

const loginReducer = (state = defaultState, action) => {
  switch (action.type) {
    case USER_LOGIN:
      return {
        ...state,
        ...action.data,
        isloggedIn: true,
      };
    case USER_LOGOUT:
      return {
        isloggedIn: false,
        token: '',
      };

    default:
      return state;
  }
};

// loginReducer.name = 'users';

export default loginReducer;
