import React, { useState, useEffect } from 'react';
import { Line, Pie } from 'react-chartjs-2';
// reactstrap components
import { Card, CardHeader, CardBody, CardFooter, CardTitle, Row, Col } from 'reactstrap';
// core components
import {
	dashboard24HoursPerformanceChart,
	dashboardEmailStatisticsChart,
	dashboardNASDAQChart,
} from '../../variables/charts.jsx';
import { get } from '../../utils/http.js';
import { useSelector } from 'react-redux';

const Dashboard = () => {
	const [metaData, setMetaData] = useState({});
	const [loading, setLoading] = useState(false);

	const userId = useSelector((state) => state.user.id);

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			const data = await get(`/posts-meta-data/status/${userId}`);
			if (data) {
				setMetaData(data[0]);
			}
			setLoading(false);
		};
		fetchData();
	}, [userId]);

	return (
		<>
			<div className="content">
				<Row>
					<Col lg="3" md="6" sm="6">
						<Card className="card-stats">
							<CardBody>
								<Row>
									<Col md="4" xs="5">
										<div className="icon-big text-center icon-warning">
											<i className="nc-icon nc-touch-id text-success" />
										</div>
									</Col>
									<Col md="8" xs="7">
										<div className="numbers">
											<p className="card-category">Total Views</p>
											<CardTitle tag="p">{metaData.counts}</CardTitle>
											<p />
										</div>
									</Col>
								</Row>
							</CardBody>
							<CardFooter>
								<hr />
								<div className="stats">
									<i className="fas fa-sync-alt" /> Update Now
								</div>
							</CardFooter>
						</Card>
					</Col>
					<Col lg="3" md="6" sm="6">
						<Card className="card-stats">
							<CardBody>
								<Row>
									<Col md="4" xs="5">
										<div className="icon-big text-center icon-warning">
											<i className="nc-icon nc-favourite-28 text-danger" />
										</div>
									</Col>
									<Col md="8" xs="7">
										<div className="numbers">
											<p className="card-category">Loves</p>
											<CardTitle tag="p">{metaData.loves}</CardTitle>
											<p />
										</div>
									</Col>
								</Row>
							</CardBody>
							<CardFooter>
								<hr />
								<div className="stats">
									<i className="far fa-calendar" /> Last day
								</div>
							</CardFooter>
						</Card>
					</Col>
					<Col lg="3" md="6" sm="6">
						<Card className="card-stats">
							<CardBody>
								<Row>
									<Col md="4" xs="5">
										<div className="icon-big text-center icon-warning">
											{/* <i className="nc-icon nc-vector text-danger" /> */}
											<svg viewBox="0 0 20 20" fill="currentColor" className="text-danger">
												<path d="M18 9.5a1.5 1.5 0 11-3 0v-6a1.5 1.5 0 013 0v6zM14 9.667v-5.43a2 2 0 00-1.105-1.79l-.05-.025A4 4 0 0011.055 2H5.64a2 2 0 00-1.962 1.608l-1.2 6A2 2 0 004.44 12H8v4a2 2 0 002 2 1 1 0 001-1v-.667a4 4 0 01.8-2.4l1.4-1.866a4 4 0 00.8-2.4z"></path>
											</svg>
										</div>
									</Col>
									<Col md="8" xs="7">
										<div className="numbers">
											<p className="card-category">Dislikes</p>
											<CardTitle tag="p">{metaData.dislikes}</CardTitle>
											<p />
										</div>
									</Col>
								</Row>
							</CardBody>
							<CardFooter>
								<hr />
								<div className="stats">
									<i className="far fa-clock" /> In the last hour
								</div>
							</CardFooter>
						</Card>
					</Col>
					<Col lg="3" md="6" sm="6">
						<Card className="card-stats">
							<CardBody>
								<Row>
									<Col md="4" xs="5">
										<div className="icon-big text-center icon-warning">
											<i className="nc-icon nc-single-copy-04 text-primary" />
										</div>
									</Col>
									<Col md="8" xs="7">
										<div className="numbers">
											<p className="card-category">Posts</p>
											<CardTitle tag="p">{metaData.posts}</CardTitle>
											<p />
										</div>
									</Col>
								</Row>
							</CardBody>
							<CardFooter>
								<hr />
								<div className="stats">
									<i className="fas fa-sync-alt" /> Update now
								</div>
							</CardFooter>
						</Card>
					</Col>
				</Row>
				<Row>
					<Col md="12">
						<Card>
							<CardHeader>
								<CardTitle tag="h5">Users Behavior</CardTitle>
								<p className="card-category">24 Hours performance</p>
							</CardHeader>
							<CardBody>
								<Line
									data={dashboard24HoursPerformanceChart.data}
									options={dashboard24HoursPerformanceChart.options}
									width={400}
									height={100}
								/>
							</CardBody>
							<CardFooter>
								<hr />
								<div className="stats">
									<i className="fa fa-history" /> Updated 3 minutes ago
								</div>
							</CardFooter>
						</Card>
					</Col>
				</Row>
				<Row>
					<Col md="4">
						<Card>
							<CardHeader>
								<CardTitle tag="h5">Email Statistics</CardTitle>
								<p className="card-category">Last Campaign Performance</p>
							</CardHeader>
							<CardBody>
								<Pie
									data={dashboardEmailStatisticsChart.data}
									options={dashboardEmailStatisticsChart.options}
								/>
							</CardBody>
							<CardFooter>
								<div className="legend">
									<i className="fa fa-circle text-primary" /> Opened{' '}
									<i className="fa fa-circle text-warning" /> Read{' '}
									<i className="fa fa-circle text-danger" /> Deleted{' '}
									<i className="fa fa-circle text-gray" /> Unopened
								</div>
								<hr />
								<div className="stats">
									<i className="fa fa-calendar" /> Number of emails sent
								</div>
							</CardFooter>
						</Card>
					</Col>
					<Col md="8">
						<Card className="card-chart">
							<CardHeader>
								<CardTitle tag="h5">NASDAQ: AAPL</CardTitle>
								<p className="card-category">Line Chart with Points</p>
							</CardHeader>
							<CardBody>
								<Line
									data={dashboardNASDAQChart.data}
									options={dashboardNASDAQChart.options}
									width={400}
									height={100}
								/>
							</CardBody>
							<CardFooter>
								<div className="chart-legend">
									<i className="fa fa-circle text-info" /> Tesla Model S{' '}
									<i className="fa fa-circle text-warning" /> BMW 5 Series
								</div>
								<hr />
								<div className="card-stats">
									<i className="fa fa-check" /> Data information certified
								</div>
							</CardFooter>
						</Card>
					</Col>
				</Row>
			</div>
		</>
	);
};

export default Dashboard;
