const { get, postPublic } = require("../../../utils/http");
const { GET_POSTS, DELETE_POST, UPDATE_POST } = require("./posts.constants");


const startSSRBuild = async () => {
    return await postPublic(process.env.REACT_APP_BUILD_SCHEDULER);
};

export const getPosts = async (url) => {
    let data = await get(url);
    data = JSON.parse(JSON.stringify(data));
    return {
        type: GET_POSTS,
        data
    }
}

export const updatePost = ({ id, value }) => {
    startSSRBuild();
    return { type: UPDATE_POST, data: { id, value } }
}

export const deletePost = id => {
    startSSRBuild()
    return {
        type: DELETE_POST,
        data: { id }
    }
}
