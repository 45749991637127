/*!

=========================================================
* Paper Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import { Nav } from 'reactstrap';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';

import logo from '../../logo.svg';

var ps;

var id;

class Sidebar extends React.Component {
	constructor(props) {
		super(props);
		this.activeRoute.bind(this);
		this.sidebar = React.createRef();
		this.state = {
			userToggleShow: { class: 'collapse', state: false },
		};
	}

	// verifies if routeName is the one active (in browser input)
	activeRoute(routeName) {
		return this.props.location.pathname.indexOf(routeName) > -1 ? 'active' : '';
	}
	componentDidMount() {
		if (navigator.platform.indexOf('Win') > -1) {
			ps = new PerfectScrollbar(this.sidebar.current, {
				suppressScrollX: true,
				suppressScrollY: false,
			});
		}
	}
	componentWillUnmount() {
		if (navigator.platform.indexOf('Win') > -1) {
			ps.destroy();
		}
	}

	handleUserClick() {
		const status = this.state.userToggleShow.state ? false : true;
		this.setState({
			userToggleShow: {
				class: status ? 'collapsing show' : 'collapsing',
				state: this.state.userToggleShow.state,
			},
		});
		clearTimeout(id);
		id = setTimeout(() => {
			const className = status ? 'collapse show' : 'collapse';
			this.setState({
				userToggleShow: { class: className, state: status },
			});
		}, 500);
	}

	render() {
		return (
			<div className="sidebar" data-color={this.props.bgColor} data-active-color={this.props.activeColor}>
				<div className="logo">
					<Link to="/admin/dashboard" className="simple-text logo-mini">
						<div className="logo-img">
							<img src={logo} alt="react-logo" />
						</div>
					</Link>
					<Link to="/admin/dashboard" className="simple-text logo-normal">
						Weaver Admin
					</Link>
				</div>
				<div className="sidebar-wrapper" ref={this.sidebar}>
					<div className="user">
						<div className="photo">
							<img src={this.props.user.avathar} alt="Avatar" />
						</div>
						<div className="danger">
							<div
								className="user-icon"
								data-toggle="collapse"
								aria-expanded={this.state.userToggleShow.state}
								onClick={() => {
									this.handleUserClick();
								}}
							>
								<span>
									{this.props.user.name}
									<b className="caret"></b>
								</span>
							</div>
							<div className={`${this.state.userToggleShow.class}`}>
								<ul className="nav">
									<li>
										<NavLink to="/admin/user-page" activeClassName="active" className="nav-link">
											<i className="nc-icon nc-single-02"></i>
											<span className="sidebar-normal">My Profile</span>
										</NavLink>
									</li>

									<li>
										<a href="#/admin/user-profile">
											<i className="nc-icon nc-settings-gear-65"></i>
											<span className="sidebar-normal">Settings</span>
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<Nav>
						{this.props.routes
							.filter((route) => route.visible)
							.map((prop, key) => {
								return (
									<li
										className={this.activeRoute(prop.path) + (prop.pro ? ' active-pro' : '')}
										key={key}
									>
										<NavLink
											to={prop.layout + prop.path}
											className="nav-link"
											activeClassName="active"
										>
											<i className={prop.icon} />
											<p>{prop.name}</p>
										</NavLink>
									</li>
								);
							})}
					</Nav>
				</div>
			</div>
		);
	}
}

export default Sidebar;
