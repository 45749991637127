import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { loginUser } from './login.actions';
import { showError, showInfo } from '../../SharedComponents/Notification/notification.actions';
import { login } from './login.service';

const initialState = { username: '', password: '' };

const Login = () => {
	const dispatch = useDispatch();
	const [state, setState] = useState(initialState);

	const handleChange = (e) => {
		const { name, value } = e.target;
		setState({ ...state, [name]: value });
	};

	const loginCheck = async (e) => {
		if (state.username !== '' && state.password !== '') {
			const user = await login({
				username: state.username,
				password: state.password,
			});
			if (user) {
				dispatch(loginUser(user));
			} else {
				dispatch(showError('Username or Password is wrong!!. Try again'));
			}
		} else {
			dispatch(showInfo('Username or Password missing.'));
		}
	};

	const enterHandler = (event) => {
		if (event.keyCode === 13 || event.which === 13 || event.key === 'Enter') {
			event.preventDefault();
			loginCheck();
		}
	};

	return (
		<div className="login-page">
			<nav className="navbar-absolute fixed-top navbar-transparent navbar navbar-expand-lg">
				<div className="container">
					<div className="navbar-wrapper">
						<a href="#pablo" className="navbar-brand">
							Hectane
						</a>
					</div>
				</div>
			</nav>
			<div className="wrapper wrapper-full-page">
				<div className="full-page section-image">
					<div className="login-page">
						<div className="container">
							<div className="row">
								<div className="ml-auto mr-auto col-md-6 col-lg-4">
									<div className="card-login card">
										<div className="card-header">
											<div className="card-header">
												<h3 className="header text-center">Login</h3>
											</div>
										</div>
										<div className="card-body" onKeyPress={enterHandler}>
											<div className="input-group">
												<div className="input-group-prepend">
													<span className="input-group-text">
														<i className="nc-icon nc-single-02"></i>
													</span>
												</div>
												<input
													placeholder="First Name..."
													type="text"
													name="username"
													className="form-control"
													autoComplete="off"
													onChange={handleChange}
												/>
											</div>
											<div className="input-group">
												<div className="input-group-prepend">
													<span className="input-group-text">
														<i className="nc-icon nc-key-25"></i>
													</span>
												</div>
												<input
													placeholder="Password"
													autoComplete="off"
													type="password"
													name="password"
													className="form-control"
													onChange={handleChange}
												/>
											</div>
											<br />
										</div>
										<div className="card-footer">
											<button
												onClick={loginCheck}
												className="btn-round mb-3 btn btn-warning btn-block"
											>
												Login
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div
							className="full-page-background"
							style={{
								backgroundImage: `url(${require('../../../assets/img/jan-sendereks.jpg')})`,
							}}
						></div>
					</div>
					<footer className="footer">
						<div className="container-fluid">
							<div className="row">
								<div className="credits ml-auto">
									<span className="copyright">
										© 2020, made with <i className="fa fa-heart heart red"></i>
									</span>
								</div>
							</div>
						</div>
					</footer>
				</div>
			</div>
		</div>
	);
};

export default Login;
