import React, { useEffect } from 'react';
import { Row, Col, Card, CardHeader, CardTitle, CardBody, Table } from 'reactstrap';
import { put, _delete } from '../../../utils/http';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { showSuccess } from '../../SharedComponents/Notification/notification.actions';
import { useDispatch, useSelector } from 'react-redux';
import { getPosts, deletePost, updatePost } from './posts.actions';
import Post from './Post';

const Posts = () => {
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const userId = useSelector((state) => state.user.id);
	const posts = useSelector((state) => state.posts);

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			dispatch(await getPosts(`/posts/all/${userId}`));
			setLoading(false);
		};
		if (posts.length === 0) fetchData();
	}, [userId, posts, dispatch]);

	const updateStatus = async (e) => {
		let { id, value } = e.target.dataset;
		value = value === '1' ? 2 : 1;
		const response = await put('/posts/update-status', {
			id,
			value,
		});
		if (response && response.status === 'success') {
			dispatch(updatePost({ id, value }));
			dispatch(showSuccess('Successfully updated the post'));
		}
	};

	const _deletePost = async (id) => {
		if (window.confirm('Arid you sure to delete?')) {
			if (id) {
				const response = await _delete(`/posts/${id}`);
				if (response && response.message === 'success') {
					dispatch(deletePost(id));
					dispatch(showSuccess('Successfully deleted the post'));
				}
			}
		}
	};

	return (
		<>
			<div className="content">
				<Row>
					<Col md="12">
						<Card>
							<CardHeader>
								<Row>
									<CardTitle tag="h4" className="col-md-8">
										Blog Post Listing
									</CardTitle>
									<div className="col-md-4 text-right">
										<Link to="/admin/create-post">
											<button className="btn btn-info">Create New Post</button>
										</Link>
									</div>
								</Row>
							</CardHeader>
							<CardBody>
								<Table responsive>
									<thead className="text-primary">
										<tr>
											<th>Sl</th>
											<th>Title</th>
											<th>SubTitle</th>
											<th>Category</th>
											<th>Tags</th>
											<th className="text-center">Edit</th>
											<th className="text-center">Status</th>
											<th className="text-center">Delete</th>
										</tr>
									</thead>
									<tbody>
										<Post
											posts={posts}
											updateStatus={updateStatus}
											deletePost={_deletePost}
											loading={loading}
										/>
									</tbody>
								</Table>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</div>
		</>
	);
};

export default Posts;
