import { SHOW_SUCCESS, SHOW_INFO, SHOW_ERROR, REMOVE_NOTIFICATION } from './notification.contants';

const showSuccess = (text) => {
	return {
		type: SHOW_SUCCESS,
		data: {
			place: 'tr',
			type: 'success',
			message: text,
			icon: 'nc-icon nc-satisfied',
			autoDismiss: 7,
		},
	};
};

const showError = (text) => {
	return {
		type: SHOW_ERROR,
		data: {
			place: 'tr',
			type: 'danger',
			message: text,
			icon: 'nc-icon nc-simple-remove',
			autoDismiss: 7,
		},
	};
};

const showInfo = (text) => {
	return {
		type: SHOW_INFO,
		data: {
			place: 'tr',
			type: 'info',
			message: text,
			icon: 'nc-icon nc-alert-circle-i',
			autoDismiss: 7,
		},
	};
};

const removeNotification = () => {
	return {
		type: REMOVE_NOTIFICATION,
		data: null,
	};
};

export { showSuccess, showError, showInfo, removeNotification };
