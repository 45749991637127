import { GET_POSTS, UPDATE_POST, DELETE_POST, UPDATE_POSTS } from "./posts.constants";
import { USER_LOGOUT } from "../../public/Login/login.constants";

const initialState = []

const postReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_POSTS:
        case UPDATE_POSTS:
            return [...state, ...action.data]
        case UPDATE_POST:
            const { id, value } = action.data;
            return state.map((post) => {
                if (post._id === id) {
                    post.status = value;
                }
                return post;
            })
        case DELETE_POST:
            return state.filter(p => p._id !== action.data.id);
        case USER_LOGOUT:

            return []
        default:
            return state
    }

}

export default postReducer