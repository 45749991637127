import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Card, CardHeader, CardTitle, Input, FormGroup, Form, CardBody, Button } from 'reactstrap';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import SimpleImage from '@editorjs/simple-image';
import List from '@editorjs/list';
import Warning from '@editorjs/warning';
import CodeTool from '@editorjs/code';
import Marker from '@editorjs/marker';

import { useSelector, useDispatch } from 'react-redux';
import { put, get } from '../../../utils/http';
import { useParams } from 'react-router-dom';
import { showInfo } from '../../SharedComponents/Notification/notification.actions';

const EditPost = () => {
	let { id } = useParams();
	const [inputs, setInputs] = useState({
		category: '',
		tags: '',
		status: 2,
		title: '',
		subTitle: '',
		route: '',
		body: [],
	});
	const userId = useSelector((state) => state.user.id);
	const dispatch = useDispatch();
	let editor = useRef({});

	useEffect(() => {
		const getPost = async (id) => {
			const data = await get(`/posts/post/${id}`);

			if (data) {
				const { category, tags, status, title, subTitle, route, body } = data;
				setInputs({
					category: category[0],
					tags: tags.join(', '),
					status,
					title,
					subTitle,
					route,
					body,
				});
			}
		};
		getPost(id);
	}, [id]);

	useEffect(() => {
		if (!editor.current.isReady) {
			editor.current = new EditorJS({
				placeholder: 'Let`s write an awesome story!',
				holder: 'editor-id',
				tools: {
					header: Header,
					image: SimpleImage,
					code: CodeTool,
					marker: Marker,
					list: {
						class: List,
						inlineToolbar: true,
					},
					warning: {
						class: Warning,
						inlineToolbar: true,
						config: {
							titlePlaceholder: 'Title',
							messagePlaceholder: 'Message',
						},
					},
				},
				data: { blocks: inputs.body },
			});
		} else {
			const clear = async () => {
				if (editor.current.isReady) {
					await editor.current.isReady;
					await editor.current.render({
						blocks: inputs.body,
					});
				}
			};
			clear();
		}
	}, [inputs.body]);

	const handleInputChange = (event) => {
		event.persist();
		setInputs((inputs) => ({
			...inputs,
			[event.target.name]: event.target.value,
		}));
	};

	const handleSubmit = async (event) => {
		if (event) {
			event.preventDefault();
		}
		let tagList = [],
			categoryList = [];

		if (inputs.tags) {
			tagList = inputs.tags.split(',').map((t) => t.trim());
		}
		if (inputs.category) {
			categoryList = [inputs.category];
		}

		if (editor.current) {
			const postContent = await editor.current.save();
			console.log(postContent);
			const postData = {
				...inputs,
				id,
				tags: tagList,
				category: categoryList,
				authorId: userId,
				updatedDate: Date.now(),
				body: postContent.blocks,
			};
			const response = await put('/posts/update-post', postData);
			if (response && response._id) {
				dispatch(showInfo('Successfully updated the post'));
			}
		}
	};

	return (
		<div className="content">
			<Row>
				<Col md="12">
					<Card>
						<CardHeader>
							<CardTitle tag="h4" className="col-md-8">
								Create New Post
							</CardTitle>
							<CardBody>
								<Form onSubmit={handleSubmit}>
									<Row>
										<Col className="pr-1" md="6">
											<FormGroup>
												<label>Title</label>
												<Input
													onChange={handleInputChange}
													name="title"
													placeholder="Title"
													type="text"
													id="title"
													value={inputs.title}
												/>
											</FormGroup>
										</Col>
										<Col className="px-1" md="6">
											<FormGroup>
												<label>Subt Title</label>
												<Input
													onChange={handleInputChange}
													name="subTitle"
													placeholder="Subt Title"
													type="text"
													id="sub-title"
													value={inputs.subTitle}
												/>
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col className="pr-1" md="6">
											<FormGroup>
												<label>Route</label>
												<Input
													onChange={handleInputChange}
													name="route"
													placeholder="Route Path"
													type="text"
													id="route"
													value={inputs.route}
												/>
											</FormGroup>
										</Col>
										<Col className="px-1" md="3">
											<FormGroup>
												<label>Category</label>
												<Input
													onChange={handleInputChange}
													name="category"
													type="select"
													id="category"
													value={inputs.category}
												>
													<option value="technology">Technology</option>
													<option value="interviews">Interviews</option>
													<option value="learnings">Learnings</option>
													<option value="travelogue">Travelouge</option>
												</Input>
											</FormGroup>
										</Col>
										<Col className="px-1" md="3">
											<FormGroup>
												<label>Status</label>
												<Input
													onChange={handleInputChange}
													type="select"
													name="status"
													id="status"
													// defaultValue="2"
													value={inputs.status}
												>
													<option value="1">Active</option>
													<option value="2">Disabled</option>
												</Input>
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col className="pr-1" md="12">
											<label>Body</label>
											<div id="editor-id" className="editor-area"></div>
										</Col>
									</Row>
									<Row>
										<Col className="pr-1" md="12">
											<FormGroup>
												<label>Tags</label>
												<Input
													onChange={handleInputChange}
													name="tags"
													placeholder="Tags"
													type="text"
													id="tags"
													value={inputs.tags}
												/>
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<div className="update ml-auto">
											<Col md="12">
												<Button className="btn" color="primary" type="submit">
													Update Post
												</Button>
											</Col>
										</div>
									</Row>
								</Form>
							</CardBody>
						</CardHeader>
					</Card>
				</Col>
			</Row>
		</div>
	);
};

export default EditPost;
