import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Card, CardHeader, CardTitle, Input, FormGroup, Form, CardBody, Button } from 'reactstrap';
import EditorJS from '@editorjs/editorjs';
import Header from '@editorjs/header';
import SimpleImage from '@editorjs/simple-image';
import List from '@editorjs/list';
import Warning from '@editorjs/warning';
import CodeTool from '@editorjs/code';
import Marker from '@editorjs/marker';

import { useSelector, useDispatch } from 'react-redux';
import { post } from '../../../utils/http';
import { showSuccess } from '../../SharedComponents/Notification/notification.actions';

const CreatePost = () => {
	const [inputs, setInputs] = useState({ status: '2', category: 'technolgy' });
	const userId = useSelector((state) => state.user.id);
	const dispatch = useDispatch();
	let editor = useRef({});
	let autoSave = useRef({});

	useEffect(() => {
		editor.current = new EditorJS({
			placeholder: 'Let`s write an awesome story!',
			holder: 'editor-id',
			tools: {
				header: {
					class: Header,
					config: {
						defaultLevel: 3,
					},
				},
				image: SimpleImage,
				code: CodeTool,
				marker: Marker,
				list: {
					class: List,
					inlineToolbar: true,
				},
				warning: {
					class: Warning,
					inlineToolbar: true,
					config: {
						titlePlaceholder: 'Title',
						messagePlaceholder: 'Message',
					},
				},
			},
		});
	}, []);

	const handleInputChange = (event) => {
		event.persist();
		setInputs((inputs) => ({
			...inputs,
			[event.target.name]: event.target.value,
		}));
	};

	const handleSubmit = (event) => {
		if (event) {
			event.preventDefault();
		}
		savePost('Sucessfully saved the post');
		clearInterval(autoSave.current);
	};

	const savePost = async (message) => {
		let tagList = [],
			categoryList = [];

		if (inputs.tags) {
			tagList = inputs.tags.split(',').map((t) => t.trim());
		}
		if (inputs.category) {
			categoryList = [inputs.category];
		}

		if (editor.current) {
			const postContent = await editor.current.save();
			const postData = {
				...inputs,
				tags: tagList,
				category: categoryList,
				authorId: userId,
				createdDate: Date.now(),
				body: postContent.blocks,
			};
			const response = await post('/posts/create-post', postData);
			if (response && response._id) {
				dispatch(showSuccess(message));
			}
		}
	};

	clearInterval(autoSave.current);
	autoSave.current = setInterval(() => {
		if (inputs && inputs.route) {
			savePost('auto saved post.');
		}
	}, 1000 * 30);

	return (
		<div className="content">
			<Row>
				<Col md="12">
					<Card>
						<CardHeader>
							<CardTitle tag="h4" className="col-md-8">
								Create New Post
							</CardTitle>
							<CardBody>
								<Form onSubmit={handleSubmit}>
									<Row>
										<Col className="pr-1" md="6">
											<FormGroup>
												<label>Title</label>
												<Input
													onChange={handleInputChange}
													name="title"
													placeholder="Title"
													type="text"
													id="title"
												/>
											</FormGroup>
										</Col>
										<Col className="px-1" md="6">
											<FormGroup>
												<label>Subt Title</label>
												<Input
													onChange={handleInputChange}
													name="subTitle"
													placeholder="Subt Title"
													type="text"
													id="sub-title"
												/>
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col className="pr-1" md="6">
											<FormGroup>
												<label>Route</label>
												<Input
													onChange={handleInputChange}
													name="route"
													placeholder="Route Path"
													type="text"
													id="route"
												/>
											</FormGroup>
										</Col>
										<Col className="px-1" md="3">
											<FormGroup>
												<label>Category</label>
												<Input
													onChange={handleInputChange}
													name="category"
													type="select"
													id="Category"
												>
													<option value="technology">Technology</option>
													<option value="interviews">Interviews</option>
													<option value="learnings">Learnings</option>
													<option value="travelogue">Travelouge</option>
													<option value="static">Static</option>
												</Input>
											</FormGroup>
										</Col>

										<Col className="px-1" md="3">
											<FormGroup>
												<label>Status</label>
												<Input
													onChange={handleInputChange}
													type="select"
													name="status"
													id="status"
													defaultValue="2"
												>
													<option value="1">Active</option>
													<option value="2">Disabled</option>
												</Input>
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<Col className="pr-1" md="12">
											<label>Body</label>
											<div id="editor-id" className="editor-area"></div>
										</Col>
									</Row>
									<Row>
										<Col className="pr-1" md="12">
											<FormGroup>
												<label>Tags</label>
												<Input
													onChange={handleInputChange}
													name="tags"
													placeholder="Tags"
													type="text"
													id="tags"
												/>
											</FormGroup>
										</Col>
									</Row>
									<Row>
										<div className="update ml-auto">
											<Col md="12">
												<Button className="btn" color="primary" type="submit">
													Save Post
												</Button>
											</Col>
										</div>
									</Row>
								</Form>
							</CardBody>
						</CardHeader>
					</Card>
				</Col>
			</Row>
		</div>
	);
};

export default CreatePost;
