import React from 'react';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';
import { Route, Switch } from 'react-router-dom';

import DemoNavbar from '../components/Navbars/DemoNavbar.jsx';
import Footer from '../components/Footer/Footer.jsx';
import Sidebar from '../components/Sidebar/Sidebar.jsx';
// import FixedPlugin from 'components/FixedPlugin/FixedPlugin.jsx';

import routes from './routes';

var ps;

class DashboardLayout extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			backgroundColor: 'white',
			activeColor: 'danger',
		};
		this.mainPanel = React.createRef();
	}
	componentDidMount() {
		if (navigator.platform.indexOf('Win') > -1) {
			ps = new PerfectScrollbar(this.mainPanel.current);
			document.body.classList.toggle('perfect-scrollbar-on');
		}
	}
	componentWillUnmount() {
		if (navigator.platform.indexOf('Win') > -1) {
			ps.destroy();
			document.body.classList.toggle('perfect-scrollbar-on');
		}
	}
	componentDidUpdate(e) {
		if (e.history.action === 'PUSH') {
			this.mainPanel.current.scrollTop = 0;
			document.scrollingElement.scrollTop = 0;
		}
	}
	handleActiveClick = (color) => {
		this.setState({ activeColor: color });
	};
	handleBgClick = (color) => {
		this.setState({ backgroundColor: color });
	};
	render() {
		return (
			<div className="wrapper">
				<Sidebar
					{...this.props}
					routes={routes}
					bgColor={this.state.backgroundColor}
					activeColor={this.state.activeColor}
				/>
				<div className="main-panel" ref={this.mainPanel}>
					<DemoNavbar {...this.props} />
					<Switch>
						{routes.map((prop, key) => {
							return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
						})}
					</Switch>
					<Footer fluid />
				</div>
				{/* <FixedPlugin
          bgColor={this.state.backgroundColor}
          activeColor={this.state.activeColor}
          handleActiveClick={this.handleActiveClick}
          handleBgClick={this.handleBgClick}
        /> */}
			</div>
		);
	}
}

export default DashboardLayout;
