import { USER_LOGIN, USER_LOGOUT } from './login.constants';

const loginUser = user => {
  return {
    type: USER_LOGIN,
    data: user,
  };
};

const logoutUser = () => {
  return {
    type: USER_LOGOUT,
  };
};

export { loginUser, logoutUser };
