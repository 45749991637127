import Dashboard from '../views/authenticated/Dashboard.jsx';
import Notifications from '../views/authenticated/Notifications.jsx';
import Icons from '../views/authenticated/Icons.jsx';
import Typography from '../views/authenticated/Typography.jsx';
import TableList from '../views/authenticated/Tables.jsx';
import UserPage from '../views/authenticated/User.jsx';
import Posts from '../views/authenticated/Posts/Posts.jsx';
import CreatePost from '../views/authenticated/CreatePost/CreatePost.jsx';
import EditPost from '../views/authenticated/EditPost/EditPost.jsx';

var routes = [
	{
		path: '/dashboard',
		name: 'Dashboard',
		icon: 'nc-icon nc-bank',
		component: Dashboard,
		layout: '/admin',
		visible: true,
	},
	{
		path: '/icons',
		name: 'Icons',
		icon: 'nc-icon nc-diamond',
		component: Icons,
		layout: '/admin',
		visible: false,
	},
	{
		path: '/notifications',
		name: 'Notifications',
		icon: 'nc-icon nc-bell-55',
		component: Notifications,
		layout: '/admin',
		visible: false,
	},
	{
		path: '/user-page',
		name: 'User Profile',
		icon: 'nc-icon nc-single-02',
		component: UserPage,
		layout: '/admin',
		visible: false,
	},
	{
		path: '/tables',
		name: 'Table List',
		icon: 'nc-icon nc-tile-56',
		component: TableList,
		layout: '/admin',
		visible: false,
	},
	{
		path: '/typography',
		name: 'Typography',
		icon: 'nc-icon nc-caps-small',
		component: Typography,
		layout: '/admin',
		visible: false,
	},
	{
		path: '/posts',
		name: 'Posts',
		icon: 'nc-icon nc-caps-small',
		component: Posts,
		layout: '/admin',
		visible: true,
	},
	{
		path: '/create-post',
		name: 'Create Post',
		icon: 'nc-icon nc-caps-small',
		component: CreatePost,
		layout: '/admin',
		visible: false,
	},
	{
		path: '/edit-post/:id',
		name: 'Edit Post',
		icon: 'nc-icon nc-caps-small',
		component: EditPost,
		layout: '/admin',
		visible: false,
	},
];
export default routes;
