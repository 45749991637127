import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import DashboardLayout from './DashboardLayout';
import { useSelector, useDispatch } from 'react-redux';
import { getAccessToken } from '../views/public/Login/login.service';
import { logoutUser } from '../views/public/Login/login.actions';

const AuthenticatedApp = () => {
	let isloggedIn = useSelector((state) => state.user.isloggedIn);
	let user = useSelector((state) => state.user);
	const dispatch = useDispatch();
	if (isloggedIn && !getAccessToken()) {
		dispatch(logoutUser());
	}
	return (
		<Switch>
			<Route path="/admin" render={(props) => <DashboardLayout {...props} user={user} />} />
			<Redirect to="/admin/dashboard" />
		</Switch>
	);
};

export default AuthenticatedApp;
