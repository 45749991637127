import React, { useRef } from 'react';
import { Router } from 'react-router-dom';
import AuthenticatedApp from './Authenticated';
import Public from './Public';
import { createBrowserHistory } from 'history';
import { useSelector } from 'react-redux';
import NotificationAlert from 'react-notification-alert';
import { useEffect } from 'react';

const hist = createBrowserHistory();

const App = () => {
	let isloggedIn = useSelector((state) => state.user.isloggedIn);
	const notification = useSelector((state) => state.notification);
	const notificationAlert = useRef();

	useEffect(() => {
		if (notificationAlert.current && notificationAlert.current.notificationAlert && notification !== null) {
			notificationAlert.current.notificationAlert(notification);
		}
	}, [notification]);

	return (
		<Router history={hist}>
			<NotificationAlert ref={notificationAlert} />
			{isloggedIn ? <AuthenticatedApp /> : <Public />}
		</Router>
	);
};

export default App;
