import React from 'react';

import { Route, Switch, Redirect } from 'react-router-dom';
import Login from '../views/public/Login/Login';

const Public = () => {
  return (
    <Switch>
      <Route path="/login" render={props => <Login />} />
      <Redirect to="/login" />
    </Switch>
  );
};

export default Public;
