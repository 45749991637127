import React from 'react';

class GlobalErrorBoundary extends React.Component {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError(error) {
    console.error(error);
    return { hasError: true };
  }
  render() {
    if (this.state.hasError) {
      return (
        <div>
          <h1>Error Catched!</h1>
        </div>
      );
    }
    return this.props.children;
  }
}

export default GlobalErrorBoundary;
