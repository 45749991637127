import { post, get } from '../../../utils/http';

let token = '';

const login = async (data) => {
	const user = await post('/users/login', data);
	if (user) {
		if (user.token) {
			token = user.token;
		}
		localStorage.setItem('user', JSON.stringify(user));
		return user;
	}
	return null;
};

const logout = () => {
	localStorage.removeItem('user');
};

const getAccessToken = () => {
	if (!token) {
		let user = JSON.parse(localStorage.getItem('user'));
		token = user.token;
	}
	return token;
};

const liveBackendCheck = async () => {
	return await get('/');
};

export { login, logout, getAccessToken, liveBackendCheck };
