import axios from 'axios';
import config from './configuration';
import { logout, getAccessToken } from '../views/public/Login/login.service';

const get = async (url) => {
	try {
		const response = await axios.get(`${config.basePath}${url}`);
		return handleResponse(response);
	} catch (error) {
		console.error(error, url);
	}
};

const postPublic = async (url) => {
	try {
		const response = await axios.post(`${url}`);
		return response;
	} catch (error) {
		console.error(error, url);
	}
};

const _delete = async (url) => {
	try {
		const response = await axios.delete(`${config.basePath}${url}`);
		return handleResponse(response);
	} catch (error) {
		console.error(error, url);
	}
};

const post = async (url, data) => {
	try {
		const response = await axios.post(`${config.basePath}${url}`, data);
		return handleResponse(response);
	} catch (error) {
		console.error(error, url);
	}
};

const put = async (url, data) => {
	try {
		const response = await axios.put(`${config.basePath}${url}`, data);
		return handleResponse(response);
	} catch (error) {
		console.error(error, url);
	}
};

const handleResponse = (response) => {
	if ([500, 501, 503, 504, 505, 506, 507, 508, 509, 510, 511].indexOf(response.status) !== -1) {
		const error = (response && response.message) || response.statusText;
		return Promise.reject(error);
	}
	return response.data;
};

axios.interceptors.response.use(
	(response) => response,
	function (error) {
		if (error.response.status !== 200 && [401, 403].indexOf(error.response.status) !== -1) {
			// auto logout if 401 Unauthorized or 403 Forbidden response returned from api
			if (error.response.data.message === 'jwt expired') {
				logout();
				window && window.location.reload(true);
			}
		}
		return Promise.reject(error);
	}
);

axios.interceptors.request.use(
	async (reqConfig) => {
		let serverCallUrl = new URL(reqConfig.url);
		if (serverCallUrl.pathname.includes('/login')) {
			return reqConfig;
		}
		const token = getAccessToken();
		if (token && serverCallUrl.href.includes(config.basePath)) {
			reqConfig.headers['authorization'] = `Bearer ${token}`;
			// config.headers['cache-control'] = `no-cache`
			return reqConfig;
		}
		return reqConfig;
	},
	function (error) {
		return Promise.reject(error);
	}
);

export { get, post, put, _delete, postPublic };
