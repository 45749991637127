import { createStore, combineReducers } from 'redux';
import loginReducer from '../views/public/Login/login.reducer';
import notificationReducer from '../views/SharedComponents/Notification/notification.reducer';
import postReducer from '../views/authenticated/Posts/posts.reducer';

const rootReducer = combineReducers({
	user: loginReducer,
	notification: notificationReducer,
	posts: postReducer
});

const store = createStore(rootReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

export default store;
