import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './layouts/App';
import * as serviceWorker from './serviceWorker';

import store from './store/store';
import { Provider } from 'react-redux';

import './assets/css/bootstrap.min.css';
import './assets/scss/core.scss';
import './assets/demo/demo.css';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import GlobalErrorBoundary from './components/ErrorHandling/GlobalErrorBoundary';

ReactDOM.render(
	// <React.StrictMode>
	<GlobalErrorBoundary>
		<Provider store={store}>
			<App />
		</Provider>
	</GlobalErrorBoundary>,
	// </React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
